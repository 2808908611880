import Link from '@components/Link';
import classNames from 'classnames';
import Styles from '../Header.module.scss';

export const TalentLinks = ({ children }) => {
  return (
    <div className={Styles.navList}>
      {/* all talent page except talent/faq  */}
      <div className={Styles.navItem}>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/talent/faq'>
          {`FAQ`}
        </Link>
      </div>

      <div className={Styles.navItem}>
        <Link className={Styles.navLink} activeClassName={Styles.activeNavItem} title='' href='/'>
          {`Go to Hire Digital`}
        </Link>
      </div>
      <div className={classNames(Styles.navItem, Styles.leftBorder)} id='login-action'>
        <Link
          className={Styles.navLink}
          activeClassName={Styles.activeNavItem}
          title=''
          href='/login'>
          {`Log In`}
        </Link>
      </div>
      <div className={Styles.navItem}>{children}</div>
    </div>
  );
};
