import { memo, Fragment, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
// import dynamic from 'next/dynamic';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import Logo from '@hiredigital/ui/Logo';

import Default from './components/Default';
import Talent from './components/Talent';
import Insights from './components/Insights';
import Enterprise from './components/Enterprise';

import { Types, Variants } from './constants';

import Styles from './Header.module.scss';

/*
  There are two types of heroes. One for hero pages (dark background) and pages (light background)
  There are several variants of pages
    - talent
    - blog (insights)
    - enterprise
    - default
*/
// export const Types = {
//   HERO: Styles.hero,
//   PAGES: Styles.pages,
// };

const Menu = ({ variant, ...props }) => {
  switch (variant) {
    case Variants.TALENT:
      return <Talent {...props} />;
    case Variants.INSIGHTS:
      return <Insights {...props} />;
    case Variants.ENTERPRISE:
      return <Enterprise {...props} />;
    case Variants.DEFAULT:
      return <Default {...props} />;
    default:
      return <Default {...props} />;
  }
};

// We memoize the menu in order to prevent it from re-rendering when type is changed
const MemoMenu = memo(Menu);

const Header = memo(
  ({
    type: initialType,
    variant,
    showBackground,
    isOffset,
    isFixed,
    buttonLabel,
    analyticsData,
  }) => {
    const [sticky, setSticky] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [ref, inView, entry] = useInView({ threshold: 1 });
    useEffect(() => {
      if (entry) {
        setSticky(!inView);
      }
    }, [inView, initialType, entry]);

    const toggleShowMenu = (option) => {
      setShowMenu(option);
    };

    return (
      <Fragment>
        <div ref={ref} />
        <header
          className={classNames(
            Styles.container,
            isFixed && Styles.isFixed,
            !isFixed && (sticky || showBackground) && Styles.sticky,
            isOffset && Styles.isOffset,
            showMenu && Styles.menuIsOpen
          )}>
          <div className={Styles.wrapper}>
            <div className={Styles.header}>
              <MemoMenu
                onMenuToggle={toggleShowMenu}
                {...{ variant, analyticsData, buttonLabel }}
              />
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
);

Header.propTypes = {
  showBackground: PropTypes.bool,
  variant: PropTypes.number,
  buttonLabel: PropTypes.string,
  analyticsData: PropTypes.object,
};

Header.defaultProps = {
  type: Types.DARK,
  variant: Variants.DEFAULT,
};

Header.Variants = Variants;
Header.Styles = Styles;
Header.Types = Types;

// Deprecated
Header.Type = Types;

export default Header;
